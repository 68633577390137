import _ from "lodash";
import {Text} from '@fluentui/react';
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS} from "../../const/local-store.const";

const ItemCell = ({item, index}: any) => {
    const {model, select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDIT_CONTACT)

    return (
        <div key={index} onClick={() => {
            setStore(LOCAL_STORE_KEYS.SELECTED_INTERNALCODE, item?.InternalCode)
            setStore(LOCAL_STORE_KEYS.SELECTED_CONTACT, {...item})
        }} tabIndex={index}
             data-is-focusable={true}
             className={` py-1 cursor-pointer px-2 border-gray border-b hover:bg-gray-100 ${select(LOCAL_STORE_KEYS.SELECTED_INTERNALCODE) === item?.InternalCode ? 'bg-gray-100' : ''} `}>
            <div className={'py-1 flex flex-col'}>
                <Text variant={'small'}
                      className={'font-bold font-[Poppins]'}>{_.get(item, 'Name')}</Text>
                <Text variant={'small'}
                      className={'min-w-[100px] font-[Poppins] italic'}>{_.get(item, 'U_ContactType')}</Text>
                <Text variant={'small'}
                      className={'min-w-[100px] font-[Poppins] font-normal'}>{_.get(item, 'U_Position')}</Text>
            </div>
        </div>
    )
}

export default ItemCell