import ContactApp from "./components/pages/contact-person";
import ConfirmDialog from "./components/pages/components/confirmation-dialog";
import {CoreUIProvider} from "@dladio/core-ui";
import {AppBridgeProvider} from "@dladio/app-bridge";

function App() {
    return (
        <AppBridgeProvider apiKey="bp-contact">
            <CoreUIProvider>
                <ContactApp/>
                <ConfirmDialog/>
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
