export enum LOCAL_STORE_KEYS {
    PAGE_STATES = 'page-status',
    EDIT_CONTACT = 'edite-contact',
    BP_CONTACT = 'bp-contact',
    SELECTED_INTERNALCODE = 'selected-internalCode',
    SELECTED_CONTACT ='selected-contact',
    BP_DATA = 'bp-data'
}

export enum PAGE_STATES {
    LOADING = 'loading',
    MUTATE = 'mutate'
}
