import {erpMethodApi} from "@dladio/service";

export const exeMethod = (method: any, body: any) => {
    return async (dispatch: any) => {
        const {data, error}: any = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate(
            {
                method: method,
                body: body
            }
        ))

        if (!error) return data
        else throw error

    }
}

export const exeMutation = (method: any, body: any) => {
    return async (dispatch: any) => {
        const {data, error}: any = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate(
            {
                method: method,
                body: body
            }
        ))

        if (!error) return data
        else throw error

    }
}