import { Spinner } from "@fluentui/react";

const Loading = () => {
    return (
            <div className={'h-[200px] flex flex-col justify-center'}>
                <Spinner label="Loading..."/>
            </div>
    )
}

export default Loading