import _ from "lodash";
import {isArray} from "@dladio/utils";
import {TextField} from "@fluentui/react";
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS} from "../../const/local-store.const";
import {AutoCompleteTextField, LabelOnLeft} from "@dladio/core-ui";

const EditeForm = () => {
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDIT_CONTACT)
    const selectedContactIndex = isArray(select(LOCAL_STORE_KEYS.BP_CONTACT)) ? select(LOCAL_STORE_KEYS.BP_CONTACT).findIndex((item: any) => (item?.InternalCode == select(LOCAL_STORE_KEYS.SELECTED_INTERNALCODE))) : 0
    const selectedContact = isArray(select(LOCAL_STORE_KEYS.BP_CONTACT)) ? select(LOCAL_STORE_KEYS.BP_CONTACT)[selectedContactIndex] : {}

    const onChangeHandler = async (event: any) => {
        const {id, value}: any = event.target
        const bpAddress: any = _.cloneDeep(select(LOCAL_STORE_KEYS.BP_CONTACT))
        bpAddress[selectedContactIndex][id] = value
        setStore(LOCAL_STORE_KEYS.BP_CONTACT, bpAddress)
    }

    const onChangeAutoComplete = (id: any, value: any) => {
        const bpAddress: any = _.cloneDeep(select(LOCAL_STORE_KEYS.BP_CONTACT))
        bpAddress[selectedContactIndex][id] = value
        setStore(LOCAL_STORE_KEYS.BP_CONTACT, bpAddress)
    }

    return (
        <>
            <div className={'pt-2 h-[100%] font-[Poppins]'}>
                <LabelOnLeft className={'font-[Poppins]'} label="Title">
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            disabled={!selectedContact}
                            id={'Title'}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            defaultKey={selectedContact?.Title}
                            options={{type: "erp", query: {name: '77d2388eee'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Contact ID'}>
                    <TextField disabled={!selectedContact} id={'Name'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.Name}
                               onChange={onChangeHandler} borderless={!selectedContact}/>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'First Name'}>
                    <TextField disabled={!selectedContact} id={'FirstName'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.FirstName}
                               onChange={onChangeHandler} borderless={!selectedContact}/>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Last Name'}>
                    <TextField disabled={!selectedContact} id={'LastName'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.LastName}
                               onChange={onChangeHandler} borderless={!selectedContact}
                    />
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Position'}>
                    <TextField disabled={!selectedContact} id={'U_Position'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.U_Position}
                               onChange={onChangeHandler} borderless={!selectedContact}
                    />
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Phone'}>
                    <TextField disabled={!selectedContact} id={'Phone1'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.Phone1}
                               onChange={onChangeHandler} borderless={!selectedContact}
                    />
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Mobile Phone'}>
                    <TextField disabled={!selectedContact} id={'MobilePhone'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.MobilePhone}
                               onChange={onChangeHandler} borderless={!selectedContact}
                    />
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Email'}>
                    <TextField disabled={!selectedContact} id={'E_Mail'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.E_Mail}
                               onChange={onChangeHandler} borderless={!selectedContact}/>

                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Contact Type'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            id={'U_ContactType'}
                            disabled={!selectedContact}
                            defaultKey={selectedContact?.U_ContactType}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: 'a6e941493a'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Primary Contact'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            id={'U_IsPrimary'}
                            disabled={!selectedContact}
                            defaultKey={selectedContact?.U_IsPrimary}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: '372e4f1d45'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Account Receivable Contact'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            id={'U_IsAccRec'}
                            disabled={!selectedContact}
                            defaultKey={selectedContact?.U_IsAccRec}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: '372e4f1d45'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Active'}>
                    <div className={'w-[300px]'}>
                        <AutoCompleteTextField
                            id={'Active'}
                            defaultKey={selectedContact?.Active}
                            disabled={!selectedContact}
                            onChange={(id: any, value: any) => {
                                onChangeAutoComplete(id, value)
                            }}
                            options={{type: "erp", query: {name: '6d408218a6'}}}
                        />
                    </div>
                </LabelOnLeft>
                <LabelOnLeft className={'font-[Poppins]'} label={'Remarks'}>
                    <TextField disabled={!selectedContact} id={'Remarks1'} className={'w-[300px] font-[Poppins]'}
                               value={selectedContact?.Remarks1}
                               onChange={onChangeHandler} borderless={!selectedContact}/>
                </LabelOnLeft>
            </div>
        </>
    )
}

export default EditeForm