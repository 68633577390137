import _ from "lodash";
import Loading from "./components/loading";
import ItemCell from "./components/item-cell";
import {isArray} from "@dladio/utils";
import EditeForm from "./components/edite-form";
import {useDispatch} from "react-redux";
import {useLocalStore} from "@dladio/hooks";
import {Dropdown, List} from "@fluentui/react";
import {EXEC_SAP_SERVICE} from "@dladio/service/dist/const/erp-method.consts";
import {showConfirmDialog} from "../../store/reducers/confirmationDialog";
import {useEffect, useState} from "react";
import {exeMethod, exeMutation} from "../actions/action";
import {Box, DefaultCommandBar, Stack} from "@dladio/core-ui";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../const/local-store.const";
import {dispatchAction, useAppBridgeSelector} from "@dladio/app-bridge";
import {middleErrorToast, middleSuccessToast} from "../../services/toast.service";

const ContactApp = () => {
    const dispatch = useDispatch()
    const {model}: any = useAppBridgeSelector()
    const [contactStatus, setContactStatus]: any = useState('tYES')
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.EDIT_CONTACT)
    const {model: PSModel, setStore: setPageState} = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)
    const listItems = isArray(select(LOCAL_STORE_KEYS.BP_CONTACT)) ? select(LOCAL_STORE_KEYS.BP_CONTACT).filter((item: any) => (item?.Active === contactStatus)) : []

    useEffect(() => {
        if (model?.params?.id) getData()
    }, [model?.params?.id])

    const getData = async () => {
        setStore(LOCAL_STORE_KEYS.BP_CONTACT, [])
        setPageState(PAGE_STATES.LOADING, true)

        dispatch(exeMethod(EXEC_SAP_SERVICE, {
            method: "GET",
            endpoint: `BusinessPartners('${model?.params?.id}')`,
            body: {
                "id": model?.params?.id
            }
        }) as any).then((data: any) => {
            setStore(LOCAL_STORE_KEYS.BP_DATA, data?.message)
            setStore(LOCAL_STORE_KEYS.BP_CONTACT, data?.message?.ContactEmployees)
        }).catch(() => {
            middleErrorToast("Contacts cannot load")
        }).finally(() => {
            setPageState(PAGE_STATES.LOADING, false)
        })

    }

    const onChangeHandler = (event: any, {key}: any) => {
        setContactStatus(key)
    }

    const onSaveHandler = async () => {
        setPageState(PAGE_STATES.LOADING, true)
        
        dispatch(exeMutation(EXEC_SAP_SERVICE, {
            method: "PATCH",
            endpoint: `BusinessPartners('${model?.params?.id}')`,
            body: {
                ContactEmployees: [...select('bp-contact')]
            }
        }) as any).then(() => {
            middleSuccessToast('Updated successfully')
        }).catch((error: any) => {
            middleErrorToast("Failed to save", error?.data?.message?.message?.value)
        }).finally(() => setPageState(PAGE_STATES.LOADING, false))
    }

    const onRenderCell = (item: any, index: any) => {
        return (
            <ItemCell item={item} index={index}/>
        );
    }

    return (
        <>
            <DefaultCommandBar actions={[
                {
                    key: 1,
                    text: 'Save',
                    iconProps: {
                        iconName: 'Save',
                    },
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    onSaveHandler()
                                },
                            })
                        );
                    }
                },
                {
                    key: 2,
                    text: 'Create New Contact',
                    iconProps: {
                        iconName: 'AddNotes',
                    },
                    onClick: () => {
                        dispatchAction({
                            action: 'navigate',
                            payload: `page/0ef9bcad66?id=${model?.params?.id}`
                        })
                    }
                }
            ]}/>

            <Stack>
                <Box className={'w-[40%] [&>div]:h-screen'}>
                    <div className={'bg-white rounded-2 pt-2'}>
                        <Dropdown
                            label="Select Type"
                            options={[
                                {
                                    key: 'tYES',
                                    text: 'Active'
                                },
                                {
                                    key: 'tNO',
                                    text: 'Inactive'
                                }]}
                            defaultSelectedKey={contactStatus}
                            onChange={onChangeHandler}
                            styles={{
                                dropdown: {width: 150},
                            }}
                        />
                        <div className={'h-[80vh] overflow-y-scroll'}>
                            {
                                isLoading ?
                                    <Loading/> :
                                    <List
                                        items={
                                            listItems
                                        }
                                        onRenderCell={onRenderCell}/>
                            }
                        </div>
                    </div>
                </Box>
                <Box className={'flex-grow [&>div]:h-screen'}>
                    {isLoading ? <Loading/> : <EditeForm/>}
                </Box>
            </Stack>
        </ >
        
    );
}


export default ContactApp